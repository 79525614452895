import { withStyles } from "@material-ui/core/styles";
import { Switch } from "@material-ui/core";

export const CustomSwitch = withStyles({
  switchBase: {
    color: '#ffffff',
    '&$checked': {
      color: '#0078ab',
    },
    '&$checked + $track': {
      backgroundColor: '#0078ab',
    },
  },
  checked: {},
  track: {},
})(Switch);