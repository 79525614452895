import {authManager} from "@common/authentication";
import _ from "lodash";

export class AdminManagerService {
    params: URLSearchParams;

    constructor() {
        this.params = new URLSearchParams(window.location.search);
    }

    getId(): string | null {
        const accessId = this.params.get("id");
        if (!_.isEmpty(accessId)) {
            return accessId;
        }
        const info = authManager.getInfoFromAdmin<{id: any}>();
        return info?.id;
    }

    getSuccess(): number {
        const success: string = this.params.get("success");
        if (!_.isEmpty(success)) {
            return +success;
        }
        const info = authManager.getInfoFromAdmin<{success: any}>();
        return +info?.success;
    }
}
