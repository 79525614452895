import React, {useState, useEffect} from "react";
import {
  ConfigurationType,
  LockTypeConfigurationListResponse,
  LockTypeConfigurations,
  UnitAccessConfiguration
} from "@common/typing";
import { AccessesService } from "@common/units-api";
import { authManager } from '@common/authentication';
import { useToasts } from "react-toast-notifications";
import _ from "lodash";
import { Icon, Input, Required, ThemeProvider, Tooltip } from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import AccessDetailStyles from "./AccessDetailsComponent.module.scss";
import { FormControlLabel } from "@material-ui/core";
import * as Shared from "./sharedComponents";

interface ConfigurationsProps {
  lockTypeId: number;
  accessConfigurations: Array<UnitAccessConfiguration>;
  accessId: number;
  isSmartHome: boolean;
  setAccessConfigurations: Function;
  addRequiredFields: Function;
  protectedConfigValues: {[key:string] : boolean};
  setProtectedConfigValues: Function;
}

export const ConfigurationsComponent: React.FunctionComponent<ConfigurationsProps> = (props) => {
  const {lockTypeId, accessConfigurations, accessId, isSmartHome, setAccessConfigurations, addRequiredFields, protectedConfigValues, setProtectedConfigValues } = props;
  const [configurations, setConfigurations] = useState<Array<LockTypeConfigurations>>([]);
  const [configurationTypes, setConfigurationTypes] = useState<Array<ConfigurationType>>([]);
  const [inputState, setInputState] : [{[key:string] : any},Function] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const unitService = new AccessesService(authManager.getJwt());
  const info = authManager.getInfoFromAdmin<{ user: string; unitId: number; }>();
  const { addToast } = useToasts();
  const eye = (<ThemeProvider theme={_default}><Tooltip message={"Mask instruction codes"} placement={"bottom"}><span><Icon.Eye className="pointer" name="info" width={18} height={18} /></span></Tooltip></ThemeProvider>);
  const eyeOff = (<ThemeProvider theme={_default}><Tooltip message={"Reveal instruction codes"} placement={"bottom"}><span><Icon.EyeOff className="pointer" name="info" width={18} height={18} /></span></Tooltip></ThemeProvider>);

  useEffect(() => {
    (async () => {
      // don't load anything when lockTypeId is not selected
      if (lockTypeId === null) return;

      //reset configs required to null
      addRequiredFields(null);

      try {
        setIsLoading(true);
        const configurationsResponse: LockTypeConfigurationListResponse = await unitService.getLockTypeConfigurationsByLockType(lockTypeId, isSmartHome);
        if ( !_.isNil(configurationsResponse)) {
          const activeConfigurations : LockTypeConfigurations[] = [];
          const configTypes: Array<ConfigurationType> = [];
          let optionalConfigsFilled = false;
          const configValues : {[key:string] : any} = {};
          const tempProtectedConfig : {[key:string] : boolean}= _.cloneDeep(protectedConfigValues);
          for (const lockConfigType of configurationsResponse.data) {
            if(lockConfigType.attributes.is_active !== true) continue //Do not display inactive config types
            const configType = configurationsResponse.included.find( ct => ct.id === lockConfigType.attributes.configuration_type_id )
            if(_.isNil(configType)) continue //Do not display config type deleted or not related to lock type
            activeConfigurations.push(lockConfigType);
            configTypes.push(configType.attributes);
            const accessConfigType = accessConfigurations.find(ac => ac.configuration_type_id === lockConfigType.attributes.configuration_type_id);
            if (!lockConfigType.attributes.always_required && !_.isNil(accessConfigType?.value)) optionalConfigsFilled = true;
            if (_.isNil(accessConfigType?.value)) {
              configValues[configType.attributes.config_type_name] = {
                value: accessConfigType?.value ?? null,
                type: "text",
                disabled: configType.attributes.system_use,
                customClass: AccessDetailStyles.passwordInput,
                icon: eye,
                id: accessConfigType?.id,
                is_protected: configType?.attributes.is_protected,
                system_use: configType?.attributes.system_use
              };
            } else {
              configValues[configType.attributes.config_type_name] = {
                value: accessConfigType?.value ?? null,
                type: configType?.attributes.is_protected ? "password" : "text",
                disabled: true,
                customClass: AccessDetailStyles.passwordInput,
                icon: configType?.attributes.is_protected ? eyeOff : eye,
                id: accessConfigType?.id,
                is_protected: configType?.attributes.is_protected,
                system_use: configType?.attributes.system_use
              };
            }
            if (configType?.attributes.is_protected) {
              tempProtectedConfig[configType?.attributes.config_type_name] = true;
            }
          }
          //TODO: Refactor to avoid using Relational ops (Like search through arrays for related values)- Use a Document/Obj aproach 
          setConfigurations(activeConfigurations);
          setConfigurationTypes(configTypes);
          setInputState(configValues);
          setProtectedConfigValues(tempProtectedConfig)
        }
      } catch (e) {
        console.error(e);
        addToast(`Error loading Unit Access information`, { appearance: "error" });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [lockTypeId]);

  const setValue = (name: string, configTypeId: number, configValueType: string, event: any)  => {
    const position = accessConfigurations.findIndex(c => c.configuration_type_id === configTypeId);
    const newValue = configValueType == "boolean" ? event.target.checked.toString() : event.target.value;
    if (position === -1 ) { //if not exist
      const configType = configurationTypes.find(c => +c.id === +configTypeId);
      accessConfigurations.push({
        access_id: accessId,
        configuration_type_id: configTypeId,
        value: newValue,
        configuration_type: {
          config_type_name: configType?.config_type_name
        }
      } as UnitAccessConfiguration);
    } else {
      accessConfigurations[position]["value"] = newValue;
    }
    setInputState({...inputState, [name]: {...inputState[name], ['value']: newValue}});
    setAccessConfigurations(accessConfigurations);
    if (protectedConfigValues[name]) {
      updateProtectedConfig(name, false);
    }
  }

  const showConfigurationsFields = () => {
    return (configurationTypes.map((config, pos) => {
      //get lockType-config record
      const lockTypeConfig = configurations.find(c => c.attributes.configuration_type_id === config.id);
      if (lockTypeConfig?.attributes?.always_required) { //adding required fields for validations
        addRequiredFields({
          fieldName: config.config_type_name,
          displayName: config.display_name,
          typeId: config.id
        });
      }
      return (
        <div className={`row ${AccessDetailStyles.customRow}`}>
          <div className="col-sm-4">
            <label>
              <strong>{config.display_name} </strong>
              {lockTypeConfig?.attributes?.always_required ? (
                <ThemeProvider theme={_default}>
                  <Required type={"error"} size={"small"} text={"*"}></Required>
                </ThemeProvider>
              ) : (
                <React.Fragment />
              )}
            </label>

          </div>
          {renderConfigurationInput(config, lockTypeConfig)}
        </div>
      )
    }));
  }

  const handleToggle = async (name: string) => {
    try{
      if (inputState[name].type === 'password') {
        let config;
        if (protectedConfigValues[name] && !_.isNil(inputState[name].id)) { // reveal config value and update value to the real one
          const unmaskedConfig = await unitService.getAccessConfigurationById(+inputState[name].id, false, info.user);
          inputState[name].value = unmaskedConfig.attributes.value;
          updateProtectedConfig(name, false);
          // update input value with unprotected config value
          const accessConfigs = _.cloneDeep(accessConfigurations);
          config = accessConfigs.find(c => c.id === +inputState[name].id);
          config.value = inputState[name].value;
          setAccessConfigurations(accessConfigs);
        }
        setInputState({
          ...inputState,
          [name]: { ...inputState[name], ['icon']: eye, ['type']: "text", ['disabled']: inputState[name].system_use }
        });
      } else {
        setInputState({
          ...inputState,
          [name]: { ...inputState[name], ['icon']: eyeOff, ['type']: 'password', ['disabled']: true}
        });
      }
    } catch (e ) {
      console.error(e);
      addToast( e.message ?? `Error loading Unit Access information`, { appearance: "error" });
    }
  }

  /**
   * update protected status of the config
   */
  const updateProtectedConfig = (name: string, flag: boolean) => {
    const protectedClone = _.cloneDeep(protectedConfigValues);
    protectedClone[name] = flag;
    setProtectedConfigValues(protectedClone);
  }

  const hideValue = (value: string) => {
    let hiddenText = ''
    for (let char = 0; char < value.length; char++)
      hiddenText+= '•';
    return hiddenText;
  }

  /**
   * Convert string to Boolean value
   * @param valor
   */
  const stringToBoolean = (valor: string) => {
    if (typeof valor === 'string' && valor.toLowerCase() === "true") {
      return true;
    }
    return false;
  }

  const renderConfigurationInput = (config: ConfigurationType, lockTypeConfig: LockTypeConfigurations | undefined) => {
    //get access-config record
    switch (config.value_type) {
      case "string": {
        return (
          <div className={`col-sm-8 ${AccessDetailStyles.configurationCol}`}>
          {inputState[config.config_type_name].type === 'password' ? (
              <ThemeProvider theme={_default}>
                <Input
                  type={inputState[config.config_type_name].type}
                  value={hideValue(inputState[config.config_type_name].value ?? lockTypeConfig?.attributes?.default_value ?? "")}
                  onChange={() => {}}
                  customClass={`${AccessDetailStyles.inputLeft} ${AccessDetailStyles.configInput}`}
                  disabled={true}
                />
              </ThemeProvider>
            ) : (
              <ThemeProvider theme={_default}>
                <Input
                  type={inputState[config.config_type_name].type}
                  value={inputState[config.config_type_name].value ?? lockTypeConfig?.attributes?.default_value ?? ""}
                  onChange={(newValue)=> setValue(config.config_type_name, config.id, config.value_type, newValue)}
                  customClass={`${AccessDetailStyles.inputLeft} ${inputState[config.config_type_name].is_protected ? AccessDetailStyles.configInput : AccessDetailStyles.fullConfigInput}`}
                  disabled={config.readonly ? true : inputState[config.config_type_name].disabled}
                />
              </ThemeProvider>
            )}
            {inputState[config.config_type_name].is_protected ? (
              <button type="button" className={`${AccessDetailStyles.icon_config} ${AccessDetailStyles.notButton}`} onClick={() => handleToggle(config.config_type_name)}>
                {inputState[config.config_type_name].icon}
              </button>
            ) : (<React.Fragment/>)}
          </div>
        )
        break;
      }
      case "boolean": {
        const checked = stringToBoolean(inputState[config.config_type_name].value);
        return (
          <div className={`col-sm-8 ${AccessDetailStyles.configurationCol_switch}`}>
            <FormControlLabel
              control={<Shared.CustomSwitch checked={checked} onChange={(newValue)=> setValue(config.config_type_name, config.id, config.value_type, newValue)} name="active" disabled={inputState[config.config_type_name].is_protected? true : false} />}
              label={checked ? "Yes" : "No"}
            />
          </div>
        );
        break;
      }
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="row">
          <div className={`col centerContent}`}>
            <h6>Loading data...</h6>
          </div>
        </div>
      ) : (
        <React.Fragment>
          {lockTypeId === null ? (
            <React.Fragment/>
          ) : (
            <div>
              {showConfigurationsFields()}
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>)
}